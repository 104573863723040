<template>
  <div style="width: 100%;height: 100%; background: #fff">
    <img width="100%" align="" src="./assets/images/loging.gif" style="margin-top:50% " />
  </div>
</template>
<script>

import { mgop } from "@aligov/jssdk-mgop";
export default {
  name: 'Home',
  data() {
    return {
      isshow: false,
      data:{
        ticket:'',
      },
      appKey:'3n3b3538+2001833223+ekdhqo',
      UserInfo: {
        openid: '',
        mobile2: '',
        Staff_Name2:''
      },
    };
  },
  async mounted() {
    // this.$store.commit("setOpenid", '8a118a5175728024017587a2c2af23be')
    // this.$store.commit("setMobile", '13456106492')
    // this.$store.commit("setusername", '吴松吉')
    // this.$store.commit("setToken", '111111')
    // await this.$router.push({
    //   path: '/index'
    // })
    //  return;
    let ticket = "";
    ticket = this.getUrlKey("ticket");

    if (ticket != "" && ticket != null) {

      this.isshow = true;
      console.log(ticket);
       mgop({
        api: "mgop.will.mqsb.getuserinfo", // 必须
        host: "https://mapi.zjzwfw.gov.cn/",
        dataType: "JSON",
        type: "POST",
        data: { ticket: ticket },
        appKey: "3n3b3538+2001833223+ekdhqo", // 必须
        onSuccess: (data) => {
          console.log("mgop run success login : " + JSON.stringify(data));
          if (data.data.result == "ok") {

            let Info = data.data;
            this.UserInfo = {
              openid: Info.openid,
              Mobile: Info.mobile2,
              username:Info.username2,
              token:Info.token
            }
            // alert(this.UserInfo)
            this.$store.commit("setOpenid", this.UserInfo.openid)
            this.$store.commit("setMobile", this.UserInfo.Mobile)
            this.$store.commit("setusername", this.UserInfo.username)
            this.$store.commit("setToken", this.UserInfo.token)
             this.$router.push({
              path: '/index'
            })
          } else {
            console.log(data.data.errmsg);
          }
        },
        onFail: (err) => {
          console.log("mgop run error. error :  " + JSON.stringify(err));
          //alert(JSON.stringify(err));
          window.location.href =
              "https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=mqsbzhjgpt&goto=https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2001833223/reserved/index.html";
        },
      });

    } else {
      window.location.href =
          "https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=mqsbzhjgpt&goto=https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2001833223/reserved/index.html";
    }

    // this.$store.commit("UPDATE_PAGE_TITLE", "阿拉一起来");
    //this.setTitle("阿拉一起来");
  },
  methods: {
    getUrlKey(name) {
      //取utl值
      return (
          decodeURIComponent(
              (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                  location.href
              ) || [, ""])[1].replace(/\+/g, "%20")
          ) || null
      );
    },
  },
}
</script>
